import axios from "axios";
import authHeader from "./auth.header.service";
const API_URL = process.env.VUE_APP_URL_API;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "/all");
  }
  getUserBoard() {
    return axios.get(API_URL + "/user", { headers: authHeader() });
  }
  getModeratorBoard() {
    return axios.get(API_URL + "/comercial", { headers: authHeader() });
  }
  getAdminBoard() {
    return axios.get(API_URL + "/admin", { headers: authHeader() });
  }
}

export default new UserService();
